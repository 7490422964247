
<template>
    <div>

        <el-row :gutter="20" class="my_row pad20">

            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <div class="grid-content dflex">
                    <div class="demo-input-suffix">
                        <span class="name_p">Фильтр</span>
                        <el-input  size="small"
                            placeholder="Id User Name"
                            prefix-icon="el-icon-filter"
                            v-model="sorcheId">
                        </el-input>
                    </div>
                     <el-button type="primary" style="margin-left:10px;" class="butpadd">Ok</el-button>
                </div>
            </el-col>
            <!-- end col -->

            <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16" >
                <div class="grid-content">
                    <table class="tableTolovCum">
                        <tr>
                            <td><span>Пациент: </span>Алишер Отабоев Максутович</td>
                            <td><span>Общая сумма:</span> 480 000 000 сум</td>
                        </tr>
                        <tr>
                            <td><span>Договор: </span>№12 от 2019-09-30T19:00:00 000Z
                                <br/><span>Баланс в договоре:</span> 2 000 000 сум</td>
                            <td><span>Оплачено:</span> 475500 сум</td>
                        </tr>
                    </table>
                    <el-button class="add-pemi mrgint" type="primary" icon="el-icon-plus" @click="tabPaymentType = true">Добавить платеж</el-button>
                </div>
            </el-col>
            <!-- end col -->

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
                <div class="grid-content">
                     <el-table class="patient_illnesses"
                        :data="tableData2"
                        border
                        show-summary
                        style="width: 100%">
                        <el-table-column
                        prop="PP"
                        label="П.П."
                        width="80">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="Наименование">
                        </el-table-column>
                        <el-table-column
                        prop="qty"
                        sortable
                        label="Кол во"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="discount"
                        sortable
                        label="Скидка"
                        width="110">
                        </el-table-column>
                        <el-table-column
                        prop="price"
                        sortable
                        label="Цена"
                        width="180">
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
            <!-- end col -->

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
                <div class="grid-content">
                     <el-table class="patient_illnesses2"
                        :data="tableData3"
                        border
                       
                        style="width: 100%">
                        <el-table-column
                        prop="type_p"
                        label="Тип оплаты">
                        </el-table-column>
                        <el-table-column
                        prop="amount"
                        label="Сумма" >
                        </el-table-column>
                        <el-table-column
                        prop="course"
                        sortable
                        label="Курс">
                        </el-table-column>
                        <el-table-column
                        prop="data"
                        sortable
                        label="Дата">
                        </el-table-column>
                        <el-table-column
                        prop="user"
                        sortable
                        label="Пользователь">
                        </el-table-column>
                        <el-table-column
                        prop="delet"
                        label="Удалить"
                        width="85">
                        <el-button type="danger" icon="el-icon-delete" circle  @click="open"></el-button>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
            <!-- end col -->

        </el-row>

        <!--starting modal  -->
        <el-drawer title="Платеж" :visible.sync="tabPaymentType"  :append-to-body="true" direction="rtl" size="50%" class="mytab_IdP BAG">
            <Cashbox></Cashbox>
        </el-drawer>

    </div>
</template>

<script>
import Cashbox from './cashbox.vue'
export default {
    data() {
        return {
            sorcheId:'',
            tabPaymentType: false,
            tableData2: [{
            PP: '1',
            name: 'Tom link toriylae ',
            qty: '2',
            discount: '3',
            price: ' 345000'
            },
            {
            PP: '2',
            name: 'Tom link toriylae ',
            qty: '1',
            discount: '23',
            price: '130500'
            }],
            tableData3: [{
            type_p: 'Карта',
            amount:'200 Сум',
            course:'Сум',
            data:'1 сум',
            user:'2019-11-14',
            rawler:'Отабоев A M',
            delet:''
            
            }]
        };
    },
    methods: {
        open() {
            this.$confirm('Это навсегда удалит файл. Продолжить?', 'Предупреждение', {
                confirmButtonText: 'Да',
                cancelButtonText: 'Отмена',
                type: 'warning'
            })
            .then(() => {
                this.$message({
                    type: 'success',
                    message: 'Удалить завершено'
                });
            })
            .catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Удалить отменено'
                });          
            });
        }
    },
    components:{
        Cashbox,
    }
}
</script>
<style lang="scss">
    .tableTolovCum .el-table__header-wrapper{display: none;}
    .patient_illnesses{margin-top: 10px;}
    .tableTolovCum{border: 1px solid #dfe6ec; border-bottom: none;width: 100%;  border-spacing: 0px; }
    .tableTolovCum td { border-bottom: 1px solid #dfe6ec; border-spacing: 0px; padding:13px; font-size: 13px; width: 50%;}
    .tableTolovCum td span{font-size:13px;font-weight: 600; margin-right:10px;}
    .patient_illnesses2{margin-top:30px;}
    .mrgint{float: right;margin-top: 10px !important;}
</style>